import React from 'react';
import {Route, Routes} from "react-router-dom";
import './App.css';
import {Form} from "./pages/Form";
import {ControlFoto} from "./pages/control/ControlFoto";
import ProtectedRoute from "./components/ProtectedRoute";
import {Login} from "./pages/Login";
import {FotoWall} from "./pages/visual/FotoWall";

function App() {
    return (
        <div>
            <Routes>
                <Route path={'/*'} element={<Form/>}/>
                <Route path={'/login'} element={<Login/>}/>
                <Route element={<ProtectedRoute/>}>
                    <Route path={'/control/foto'} element={<ControlFoto/>}/>
                </Route>
                <Route path={'/visual/foto/'} element={<FotoWall/>}/>
                {/*<Route path={'/visual/foto/:w/:h'} element={<FotoWall/>}/>*/}
            </Routes>
        </div>
    );
}

export default App;
