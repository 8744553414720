import React, {useEffect, useState} from "react";
import {Outlet, useNavigate} from 'react-router-dom'

const ProtectedRoute = ({children}) => {
    document.body.style.backgroundColor = "#121212";
    const [user, setUser] = useState(localStorage.getItem('user'))
    const navigate = useNavigate()

    useEffect(() => {
        if(!user) {
            navigate('/login', {replace:true})
        }
    },[])


    return children ? children : <Outlet/>;
};

export default ProtectedRoute
