import React, {useState} from "react";
import {ControlLayout} from "../../layouts/ControlLayout";
import {
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    IconButton,
    Stack,
    Typography
} from "@mui/material";
import {Delete, Fullscreen, Refresh} from "@mui/icons-material";
import useAxios from "axios-hooks";
import axios from "axios";
import _ from 'lodash'
import {bucketUrl, serverUrlFoto} from "../../configuration";
import {useTranslation} from "react-i18next";

export const ControlFoto = () => {
    const {t} = useTranslation()

    const [{data: images, loading, error}, refetch] = useAxios(`${serverUrlFoto}/photo/approved`)
    const [open, setOpen] = useState(false)

    const deleteImage = async (id) => {
        await axios.post(`${serverUrlFoto}/photo/${id}/block`)
            .finally(() => refetch())
    }

    const reset = async () => {
        await axios.post(`${serverUrlFoto}/clear/foto`, {})
            .then(() => setOpen(!open))
            .finally(() => refetch())
    }

    return (
        <ControlLayout>
            <Dialog open={open} onClose={() => setOpen(!open)}>
                <DialogContent sx={{backgroundColor:'#121212'}}>
                    <DialogTitle>
                        Vuoi eliminare tutte le foto?
                    </DialogTitle>
                    <DialogActions>
                        <Button onClick={() => setOpen(!open)} variant={"outlined"}>No</Button>
                        <Button onClick={reset} variant={"contained"} color={"error"}>Si</Button>
                    </DialogActions>
                </DialogContent>
            </Dialog>
            <Typography variant={'h4'} fontWeight={"bold"}>FOTO</Typography>
            <Divider sx={{my: 2}}/>
            <Typography variant={"h6"} sx={{pt: 2}}>
                Moderazione
            </Typography>
            <Stack sx={{py: 1}} direction={"row"} justifyContent={"space-between"}>
                <Button onClick={() => refetch()} variant={"contained"} startIcon={<Refresh/>}>Ricarica ({images?.length||0})</Button>
                <Button onClick={() => setOpen(!open)} variant={"outlined"} color={"error"} startIcon={<Delete/>}>Reset risultati</Button>
            </Stack>

            <Box sx={{py: 2}}>
                {loading ? <CircularProgress/>
                    :
                    <Stack direction={'row'} flexWrap={'wrap'} style={{
                        width: '100%',
                    }}>
                        {
                            (!loading && !error && images) &&
                            _.orderBy(images, 'createdAt', 'desc').map((image, i) =>
                                <div key={i} style={{
                                    width: '10rem',
                                    height: '12rem',
                                    position: "relative",
                                }}>
                                    <Stack sx={{position: 'absolute', bottom: 0, width: '100%', p: 1}} direction={'row'}
                                           justifyContent={"space-between"}>
                                        <IconButton onClick={() => deleteImage(image.id)}
                                                    sx={{backgroundColor: 'error.main'}}>
                                            <Delete fontSize="inherit" color={"white"}/>
                                        </IconButton>
                                        <IconButton component={'a'}
                                                    href={`https://storage.googleapis.com/bea-2022/${image.filename}`}
                                                    target={"_blank"} sx={{backgroundColor: 'primary.main'}}>
                                            <Fullscreen/>
                                        </IconButton>
                                    </Stack>
                                    <img style={{width: '100%', height: '100%', objectFit: 'cover'}}
                                         src={`${bucketUrl}/${image.filename}`}/>
                                </div>
                            )
                        }
                    </Stack>
                }
            </Box>
        </ControlLayout>
    )
}
