import React from "react";
import {Box} from "@mui/material";
import {conferenceId, toolpressServerUrl} from "../configuration";
import useAxios from "axios-hooks";
import {LangSwitcher} from "../components/LangSwitcher";

export const LogoHeader = ({children}) => {
    const [{data: logoUrl, loading: loadingLogo, error: errorLogo}] = useAxios({
        url: `${toolpressServerUrl}/${conferenceId}/logo`,
        method: 'GET'
    })

    return(
        <Box>
            <Box sx={{position:'absolute', top:'1rem', right:'1rem'}}>
                <LangSwitcher/>
            </Box>
            <Box sx={{textAlign:'center', width:'100%', pt:10}}>
                <img src={'/assets/logo.png'} style={{width: '100%', maxWidth: '14.563rem', marginBottom:'1.5rem'}}/>
                {children}
            </Box>
        </Box>
    )
}
