import {customTheme} from "../theme/customTheme";
import {Backdrop, CircularProgress} from "@mui/material";
import React from "react";

const CustomBackdrop = ({open}) => {
    return (
        <Backdrop
            sx={{color: customTheme.palette.primary.main, zIndex: (theme) => theme.zIndex.drawer + 1}}
            open={open}>
            <CircularProgress color="inherit"/>
        </Backdrop>
    )
}

export default CustomBackdrop