import React, {useState} from "react";
import {useNavigate} from "react-router-dom";
import {Box, Button, Container, Stack, TextField} from "@mui/material";

export const Login = () => {
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const navigate = useNavigate()

    const logIn = async (e) => {
        e.preventDefault()
        if((email === 'matilde@plesh.co' || email === 'gianmarco@plesh.co' || email === 'davide@plesh.co') && password === 'Plesh2021') {
            navigate('/control/foto', {replace: true})
            localStorage.setItem('user', email)
        }
    }

    return (
        <Container maxWidth={"sm"} style={{textAlign:"center"}}>
            <img src={'/assets/logo.png'} style={{width: 'min(100%, 20rem)', padding: '2rem'}}/>
            <form onSubmit={logIn}>
                <Stack direction={'column'} spacing={3}>
                    <TextField required placeholder={'Email'} onChange={(e) => setEmail(e.target.value)} type={"email"}/>
                    <TextField required placeholder={'Password'} onChange={(e) => setPassword(e.target.value)}
                               type={"password"}/>
                    <Box sx={{width: '100%', textAlign: 'center'}}>
                        <Button type={'submit'} variant={"contained"}>login</Button>
                    </Box>
                </Stack>
            </form>
        </Container>
    )
}
